/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type PaymentInvoicesUnpaginatedListCurrencyCode = typeof PaymentInvoicesUnpaginatedListCurrencyCode[keyof typeof PaymentInvoicesUnpaginatedListCurrencyCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentInvoicesUnpaginatedListCurrencyCode = {
  AUD: 'AUD',
  BRL: 'BRL',
  CAD: 'CAD',
  CHF: 'CHF',
  CNY: 'CNY',
  DKK: 'DKK',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  IDR: 'IDR',
  INR: 'INR',
  JPY: 'JPY',
  KRW: 'KRW',
  MXN: 'MXN',
  NOK: 'NOK',
  NZD: 'NZD',
  RUB: 'RUB',
  SEK: 'SEK',
  SGD: 'SGD',
  THB: 'THB',
  TRY: 'TRY',
  TWD: 'TWD',
  USD: 'USD',
  VND: 'VND',
  ZAR: 'ZAR',
} as const;
