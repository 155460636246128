import { Box } from '@mui/material'

import type { MenuProps } from '@/types/common'

import { Header } from '@/components/organisms/admin/_common/Header'
import { AdminSidebar } from '@/components/organisms/admin/_common/sidebar'
import {
  StyledMain,
  StyledMainSection,
} from '@/components/templates/admin/AdminMenu/style'
import { Breadcrumbs } from '@/components/uiParts/BreadCrumb'
import { ConditionsContextProvider } from '@/contexts/Conditions'
import { Snackbar } from '@/Snackbar'

/** Admin用のtop & side メニュー */
export const AdminMenu = ({ breadcrumbs, children }: MenuProps) => (
  <>
    <Header />
    <StyledMain>
      <AdminSidebar />
      <ConditionsContextProvider>
        <StyledMainSection>
          {breadcrumbs && (
            <Box sx={{ mt: 3 }}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </Box>
          )}
          {children}
        </StyledMainSection>
      </ConditionsContextProvider>
      <Snackbar />
    </StyledMain>
  </>
)
