import {
  YardCarryInTimeSpanEnum,
  PayerTypeEnum,
  TradeKnowledgeLevelEnum,
  TransportMethodEnum,
} from '@api/models'

/** Admin権限 */
export const AUTHORITY_ADMIN = 'admin'

/** User権限 */
export const AUTHORITY_USER = 'user'

/** ログイン直後に遷移する画面URL */
export const USER_ROOT_URL = '/user/dashboard'
export const ADMIN_ROOT_URL = '/admin'
export const PORTRICH_ROOT_URL = '/admin/quotesrequestlist/ocean'
export const FORWARDER_ROOT_URL = '/admin/booking'

export const NOT_FOUND_URL = '/404'

/** 通貨 */
export const CURRENCY_UNITS = {
  USD: '$',
  JPY: '¥',
  VND: '₫',
  EUR: '€',
  CNY: 'Y',
  HKD: 'HK$',
  INR: 'Rs',
  SGD: 'S$',
  AUD: 'A$',
  SEK: 'SEK',
  NOK: 'NKr',
  TRY: '₺',
  ZAR: 'R',
  MXN: 'Mex$',
  GBP: '£',
  KRW: 'W',
  TWD: 'NT$',
  IDR: 'Rp',
  THB: 'Bht',
  NZD: 'NZ$',
  DKK: 'Dkr',
  CHF: 'SwF',
  RUB: '₽',
  CAD: 'Can$',
  BRL: 'R$',
}

export const FREIGHT_METHOD = {
  OCEAN: 0,
  TRUCK: 1,
  AIR: 2,
}

export const INCOTERMS = [
  'EXW',
  'FCA',
  'FAS',
  'FOB',
  'CFR',
  'CIF',
  'CPT',
  'CIP',
  'DPU',
  'DAP',
  'DDP',
  'DDU',
]

export const ORDER_BY_CREATED_AT = {
  ASK: 'created_at',
  DESK: '-created_at',
}

export const ORDER_BY_UPDATED_AT = {
  ASK: 'updated_at',
  DESK: '-updated_at',
}

export const ORDER_BY_CASE_NUMBER = {
  ASK: 'portrich_case_number',
  DESK: '-portrich_case_number',
}

export const ORDER_BY_CASE_NUMBER_BILLING = {
  ASK: 'shipment__portrich_case_number',
  DESK: '-shipment__portrich_case_number',
}

export const ORDER_BY_PAYMENT_DEADLINE = {
  ASK: 'payment_deadline',
  DESK: '-payment_deadline',
}

export const ORDER_BY_CUT_DAY = {
  ASK: 'cut_day',
  DESK: '-cut_day',
}

export const ORDER_BY_ETD = {
  ASK: 'etd',
  DESK: '-etd',
}

export const ORDER_BY_ETA = {
  ASK: 'eta',
  DESK: '-eta',
}

export const SCHEDULE_TYPE = {
  ETD: 0,
  ETA: 1,
  DESIRED_DELIVERY_DATETIME: 2,
}

export const COMPONENT_TYPE = {
  FCL: 0,
  LCL: 1,
  DRAYAGE: 2,
  TRUCK: 3,
  CUSTOMS: 4,
  AIR: 5,
}

export const QUOTE_REQUEST_COMPONENT_TYPE = {
  OCEAN: 0,
  TRUCK: 1,
  AIR: 2,
}

export const QUOTE_COMPONENT_TYPE = {
  OCEAN: 0,
  OTHER: 1,
  AIR: 2,
}

export const INVOICE_TYPE = {
  INDIVIDUAL: 0,
  CONSOLIDATED: 1,
}

export const BILLING_INFO_CATEGORY = {
  USER_COMPANY: 1,
  FORWARDER: 2,
}

export const invoiceRouteToIndexMap: Record<string, number> = {
  '/admin/invoice/individual': INVOICE_TYPE.INDIVIDUAL,
  '/admin/invoice/consolidated': INVOICE_TYPE.CONSOLIDATED,
}

export const invoiceTabRoutes = {
  [INVOICE_TYPE.INDIVIDUAL]: '/admin/invoice/individual',
  [INVOICE_TYPE.CONSOLIDATED]: '/admin/invoice/consolidated',
}

export const quoteListRouteToIndexMap: Record<string, number> = {
  '/admin/quotes/ocean': QUOTE_COMPONENT_TYPE.OCEAN,
  '/admin/quotes/other': QUOTE_COMPONENT_TYPE.OTHER,
  '/admin/quotes/air': QUOTE_COMPONENT_TYPE.AIR,
}

export const quoteListTabRoutes = {
  [QUOTE_COMPONENT_TYPE.OCEAN]: '/admin/quotes/ocean',
  [QUOTE_COMPONENT_TYPE.OTHER]: '/admin/quotes/other',
  [QUOTE_COMPONENT_TYPE.AIR]: '/admin/quotes/air',
}

export const sellingPriceListTabRoutes = {
  [COMPONENT_TYPE.FCL]: '/admin/selling/fcl',
  [COMPONENT_TYPE.LCL]: '/admin/selling/lcl',
  [COMPONENT_TYPE.DRAYAGE]: '/admin/selling/drayage',
  [COMPONENT_TYPE.TRUCK]: '/admin/selling/truck',
  [COMPONENT_TYPE.CUSTOMS]: '/admin/selling/customs',
  [COMPONENT_TYPE.AIR]: '/admin/selling/air',
}

export const purchaseTabRoutes = {
  [COMPONENT_TYPE.FCL]: '/admin/purchase/fcl',
  [COMPONENT_TYPE.LCL]: '/admin/purchase/lcl',
  [COMPONENT_TYPE.DRAYAGE]: '/admin/purchase/drayage',
  [COMPONENT_TYPE.TRUCK]: '/admin/purchase/truck',
  [COMPONENT_TYPE.CUSTOMS]: '/admin/purchase/customs',
  [COMPONENT_TYPE.AIR]: '/admin/purchase/air',
}

export const purchaseRouteToIndexMap: Record<string, number> = {
  '/admin/purchase/fcl': COMPONENT_TYPE.FCL,
  '/admin/purchase/lcl': COMPONENT_TYPE.LCL,
  '/admin/purchase/drayage': COMPONENT_TYPE.DRAYAGE,
  '/admin/purchase/truck': COMPONENT_TYPE.TRUCK,
  '/admin/purchase/customs': COMPONENT_TYPE.CUSTOMS,
  '/admin/purchase/air': COMPONENT_TYPE.AIR,
}

export const quotesRequestListTabRoutes = {
  [QUOTE_REQUEST_COMPONENT_TYPE.OCEAN]: '/admin/quotesrequestlist/ocean',
  [QUOTE_REQUEST_COMPONENT_TYPE.TRUCK]: '/admin/quotesrequestlist/truck',
  [QUOTE_REQUEST_COMPONENT_TYPE.AIR]: '/admin/quotesrequestlist/air',
}

export const quotesRequestListRouteToIndexMap: Record<string, number> = {
  '/admin/quotesrequestlist/ocean': QUOTE_REQUEST_COMPONENT_TYPE.OCEAN,
  '/admin/quotesrequestlist/truck': QUOTE_REQUEST_COMPONENT_TYPE.TRUCK,
  '/admin/quotesrequestlist/air': QUOTE_REQUEST_COMPONENT_TYPE.AIR,
}

export const forwarderRouteToIndexMap: Record<string, number> = {
  '/admin/company-management/forwarder/ocean': 0,
  '/admin/company-management/forwarder/air': 1,
}

export const forwarderTabRoutes = {
  0: '/admin/company-management/forwarder/ocean',
  1: '/admin/company-management/forwarder/air',
}

export const priceManagementRouteToIndexMap: Record<string, number> = {
  '/admin/price-managements/fcl': COMPONENT_TYPE.FCL,
  '/admin/price-managements/lcl': COMPONENT_TYPE.LCL,
  '/admin/price-managements/drayage': COMPONENT_TYPE.DRAYAGE,
  '/admin/price-managements/truck': COMPONENT_TYPE.TRUCK,
  '/admin/price-managements/customs': COMPONENT_TYPE.CUSTOMS,
}

// TODO:Tabsが順番に依存している。定数を使うと通関が4になっているため、現状使えない
export const priceManagementTabRoutes = {
  [COMPONENT_TYPE.FCL]: '/admin/price-managements/fcl',
  [COMPONENT_TYPE.LCL]: '/admin/price-managements/lcl',
  [COMPONENT_TYPE.DRAYAGE]: '/admin/price-managements/drayage',
  [COMPONENT_TYPE.TRUCK]: '/admin/price-managements/truck',
  [COMPONENT_TYPE.CUSTOMS]: '/admin/price-managements/customs',
}

export const sellingPriceListRouteToIndexMap: Record<string, number> = {
  '/admin/selling/fcl': COMPONENT_TYPE.FCL,
  '/admin/selling/lcl': COMPONENT_TYPE.LCL,
  '/admin/selling/drayage': COMPONENT_TYPE.DRAYAGE,
  '/admin/selling/truck': COMPONENT_TYPE.TRUCK,
  '/admin/selling/customs': COMPONENT_TYPE.CUSTOMS,
  '/admin/selling/air': COMPONENT_TYPE.AIR,
}

export const SHIPPING_COMPANY_TYPE = {
  MAERSK: 1,
  SEALAND_A_MAERSK: 3,
  ONE: 4,
}

export const APPROACH_TYPE = {
  CUT: 0,
  ETD: 1,
  ETA: 2,
}

export const APPROACH_TYPES = {
  [APPROACH_TYPE.CUT]: 'CY CUT',
  [APPROACH_TYPE.ETD]: 'ETD',
  [APPROACH_TYPE.ETA]: 'ETA',
} as const

export const FILTER_RAGE_TYPES = {
  TOMORROW: 0,
  WEEK: 1,
  TODAY: 2,
}

export const PAYMENT_TYPE = {
  UPLOADED: 1,
}

export const FREIGHT_METHOD_TYPE = {
  [FREIGHT_METHOD.OCEAN]: 'OCEAN',
  [FREIGHT_METHOD.TRUCK]: 'TRUCK',
  [FREIGHT_METHOD.AIR]: 'AIR',
} as const

export const FREIGHT_COLLECTIONS = {
  PRE_PRE: 0,
  PRE_COL: 1,
  COL_COL: 2,
  COL_PRE: 3,
}

export const FREIGHT_COLLECTIONS_TYPE = {
  [FREIGHT_COLLECTIONS.PRE_PRE]: 'PRE_PRE',
  [FREIGHT_COLLECTIONS.PRE_COL]: 'PRE_COL',
  [FREIGHT_COLLECTIONS.COL_COL]: 'COL_COL',
  [FREIGHT_COLLECTIONS.COL_PRE]: 'COL_PRE',
}

export const FREIGHT_COLLECTIONS_PAYER_TYPE = {
  [FREIGHT_COLLECTIONS.PRE_PRE]: {
    master_payment_type: PayerTypeEnum.PREPAID,
    house_payment_type: PayerTypeEnum.PREPAID,
  },
  [FREIGHT_COLLECTIONS.PRE_COL]: {
    master_payment_type: PayerTypeEnum.PREPAID,
    house_payment_type: PayerTypeEnum.COLLECT,
  },
  [FREIGHT_COLLECTIONS.COL_COL]: {
    master_payment_type: PayerTypeEnum.COLLECT,
    house_payment_type: PayerTypeEnum.COLLECT,
  },
  [FREIGHT_COLLECTIONS.COL_PRE]: {
    master_payment_type: PayerTypeEnum.COLLECT,
    house_payment_type: PayerTypeEnum.PREPAID,
  },
}

// Documents_for_tradeがリレーションを持つモデル
export const DOCUMENT_RELATION_TYPE = {
  SHIPMENT: 'shipment',
  MARINE_PRICE_MANAGEMENT: 'marine_trade_price_management',
  CUSTOMS_PRICE_MANAGEMENT: 'customs_clearance_price_management',
}

export const PRICE_TYPE = {
  OCEAN_FREIGHT: 1,
  ORIGIN_CUSTOMS_DUTY_OR_CONSUMPTION_TAX: 25,
  ORIGIN_CUSTOMS_CLEARANCE_FEE: 26,
  ORIGIN_CUSTOMS_HANDLING_FEE: 27,
  ORIGIN_CUSTOMS_INSPECTION_FEE: 28,
  DEST_CUSTOMS_DUTY_OR_CONSUMPTION_TAX: 51,
  DEST_CUSTOMS_CLEARANCE_FEE: 52,
  DEST_CUSTOMS_HANDLING_FEE: 53,
  DEST_CUSTOMS_INSPECTION_FEE: 54,
}

export const COMPANY_TYPE = {
  USER_COMPANY: 'userCompany',
  MARINE_FORWARDER: 'marineForwarder',
  AIR_FORWARDER: 'airForwarder',
  SHIPPING_COMPANY: 'shippingCompany',
  TRUCK_CARRIER: 'truckCarrier',
  CUSTOMS_BROKER: 'customsBroker',
  WAREHOUSING_COMPANY: 'warehousingCompany',
}

/** 文言 */
export const LABEL = {
  ORIGIN_DELIVERY: 'Origin Delivery',
  ORIGIN_CUSTOMS: 'Origin Customs',
  ORIGIN_CUSTOMS_FEE: 'Origin Customs Fee',
  ORIGIN_CUSTOMS_HANDLING_FEE: 'Origin Customs Handling Fee',
  DESTINATION_DELIVERY: 'Destination Delivery',
  DESTINATION_CUSTOMS: 'Destination Customs',
  DESTINATION_CUSTOMS_FEE: 'Destination Customs Fee',
  DESTINATION_CUSTOMS_HANDLING_FEE: 'Destination Customs Handling Fee',
  DANGEROUS_GOODS: 'Dangerous Goods',
}

/** 輸送形態 : FCLの場合、0 / LCLの場合、1 */
export const SHIPPING_METHOD = {
  FCL: 0,
  LCL: 1,
}

export const SHIPPING_METHOD_TYPE = {
  [SHIPPING_METHOD.FCL]: 'FCL',
  [SHIPPING_METHOD.LCL]: 'LCL',
} as const

export const FREIGHT_SHIPPING_METHOD_TYPE = {
  ...SHIPPING_METHOD_TYPE,
  [FREIGHT_METHOD.AIR]: 'AIR',
}

export const LOCATION = {
  ORIGIN: 0,
  DESTINATION: 1,
}

export const LOCATION_TYPES = {
  [LOCATION.ORIGIN]: 'Origin',
  [LOCATION.DESTINATION]: 'Destination',
} as const

export const CUSTOMS_PRICE_TYPE_KEYS = [
  'origin_customs_fee',
  'origin_handling_fee',
  'destination_customs_fee',
  'destination_handling_fee',
]

export const LAND_TRANSPORT_PRICE_TYPE_KEYS = [
  'origin_land_freight_fee',
  'destination_land_freight_fee',
]
/** 見積登録タブ */
export const QUOTES_REGISTER_TYPES = {
  SELECT_PURCHASE: 'select_purchase_price',
  REGISTER_WITH_PURCHASE: 'purchase_price_registration',
}

/** 見積登録タブルート */
export const QUOTES_REGISTER_TAB_ROUTES = {
  [QUOTES_REGISTER_TYPES.SELECT_PURCHASE]: 0,
  [QUOTES_REGISTER_TYPES.REGISTER_WITH_PURCHASE]: 1,
}

/** ドキュメント */
export const FILE_TYPE = {
  DOCUMENT: 'document',
  PRIVATE_DOCUMENT: 'private_document',
  USER_DOCUMENT: 'user_document',
}
export const FILTERED_DATE_TYPE = {
  CUT: 0,
  ETD: 1,
  ETA: 2,
  COLLECT: 3,
  CARRY_OUT: 4,
  TASK_DEADLINE: 5,
}
/** 輸入 or 輸出 : 輸出の場合、0 / 輸入の場合、1 */
export const TRANSPORT = {
  EXPORT: 0,
  IMPORT: 1,
}
/** Bookingステータス */
export const BOOKING_STATUS = {
  NO_STATUS: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  FAILED: 3,
  CANCELED: 4,
}
/** 台貫依頼の有無 */
export const NEED_TRUCK_SCALE = {
  UNNECESSARY: 0,
  NEED: 1,
}
/** シャーシ */
export const CHASSIS = {
  TWIN_AXLE: 0,
  TRI_AXLE: 1,
}

// ------------------ NOTE: 下記以降, 翻訳対応定数  ------------------ //

/** フィルターの日付タイプ */
export const FILTERED_DATE_TYPES = {
  [FILTERED_DATE_TYPE.CUT]: 'cy_cut',
  [FILTERED_DATE_TYPE.ETD]: 'etd',
  [FILTERED_DATE_TYPE.ETA]: 'eta',
  [FILTERED_DATE_TYPE.COLLECT]: 'filtered_date_collect',
  [FILTERED_DATE_TYPE.CARRY_OUT]: 'release_date',
  [FILTERED_DATE_TYPE.TASK_DEADLINE]: 'filtered_date_task_deadline',
}

export const TRANSPORT_TYPE = {
  [TRANSPORT.EXPORT]: 'export',
  [TRANSPORT.IMPORT]: 'import',
} as const

/** 輸送拠点 */

export const BOOKING_STATUS_TYPES = {
  [BOOKING_STATUS.NO_STATUS]: 'no_status',
  [BOOKING_STATUS.IN_PROGRESS]: 'progress',
  [BOOKING_STATUS.COMPLETED]: 'completion',
  [BOOKING_STATUS.FAILED]: 'invalid',
  [BOOKING_STATUS.CANCELED]: 'cancel',
} as const

export const NEED_TRUCK_SCALE_TYPES = {
  [NEED_TRUCK_SCALE.UNNECESSARY]: 'none',
  [NEED_TRUCK_SCALE.NEED]: 'exist',
} as const

/** 配送関連 */
export const DELIVERY = {
  WORK_PLACE: 'work_place',
  DESIRED_DATE_TIME: 'desired_date_time',
  PICK_UP_DATE_TIME: 'desired_collection_date_time',
  CUSTOMS_CLEARANCE: 'customs_clearance_date',
  DELIVERY_DATES: 'desired_delivery_date_and_time',
  CONTAINER_SIZE: 'container_size',
  TRUCK_SIZE: 'truck_size',
}

export const CHASSIS_TYPES = {
  [CHASSIS.TWIN_AXLE]: 'twin_axle',
  [CHASSIS.TRI_AXLE]: 'tri_axle',
} as const

/** 支払い状況 */
export const PAYMENT_TYPES = {
  paid: 'payment_paid',
  unpaid: 'payment_unpaid',
}

/** 通関種類 */
export const CUSTOM_TYPES = {
  [LOCATION.ORIGIN]: 'export_customs_clearance',
  [LOCATION.DESTINATION]: 'import_customs_clearance',
} as const

export const BUTTON_LIST = [
  'fcl',
  'lcl',
  'drayage',
  'truck',
  'customs_clearance',
]

export const BUTTON_LIST_WITH_AIR = [...BUTTON_LIST, 'air']

export const INVOICE_BUTTON_LIST = [
  'individual_invoice',
  'consolidated_invoice',
]

/** 陸送作業時間帯 */
export const LAND_TRANSPORT_WORK_TIME_SPAN = {
  [YardCarryInTimeSpanEnum.AM]: 'am',
  [YardCarryInTimeSpanEnum.PM]: 'pm',
  [YardCarryInTimeSpanEnum.TIME_SPECIFIED]: 'time_specified',
  [YardCarryInTimeSpanEnum.WITHIN_SAME_DAY]: 'within_same_day',
}

export const LAND_TRANSPORT_METHODS = {
  [TransportMethodEnum.CHARTER]: 'charter',
  [TransportMethodEnum.CHARTER_NOT_SAME_DAY]: 'charter_not_same_day',
  [TransportMethodEnum.CHARTER_SAME_DAY]: 'charter_same_day',
  [TransportMethodEnum.CONSOLIDATION]: 'consolidation',
  [TransportMethodEnum.DRAYAGE]: 'drayage',
}

/** 貿易知識レベル */
export const TRADE_KNOWLEDGE_LEVEL = {
  [TradeKnowledgeLevelEnum.BEGINNER]: 'enum.TRADE_KNOWLEDGE_LEVEL.BEGINNER',
  [TradeKnowledgeLevelEnum.INTERMEDIATE]:
    'enum.TRADE_KNOWLEDGE_LEVEL.INTERMEDIATE',
  [TradeKnowledgeLevelEnum.ADVANCED]: 'enum.TRADE_KNOWLEDGE_LEVEL.ADVANCED',
}

// ------------------ NOTE: 翻訳対応定数 おわり ------------------ //

export const PASSWORD_MIN_LENGTH = 6
